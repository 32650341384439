/* eslint-disable max-classes-per-file */

import { Model }                       from '@mathquis/modelx/';
import { ApiCollection }               from 'Collections/ApiCollection';
import { InterventionStatusReference } from 'Models/intervention/InterventionStatusModel';
import { interventionStatusColors }    from 'Models/intervention/InterventionStatusModel';
import StaffMemberModel                from 'Models/rh/StaffMemberModel';
import { IPlanningEventModel }         from 'components/Planning/PlanningTypings';
import { computed }                    from 'mobx';
import moment                          from 'moment';
import Proto                           from 'proto/proto';
import { getIdFromUrn }                from 'tools/UrnTools';

type InterventionResource = { urn: Urn; resourceUrn: Urn; };

export interface IInterventionPlanningEventAttributes {
	activityLocationTitle: string;
	allInterventionOperationsStatus: string;
	animated: boolean,
	anomalyCount: number | null | undefined;
	anomalyLevel: number | null | undefined;
	card: Proto.Service.Intervention.PlanningInterventionCard;
	color: string;
	endDate: string;
	id: id;
	operationLabels: string[];
	startDate: string;
	statusReference: string;
	taskId: id;
	technicianUrns: string[];
	updatedAt?: number;
	value: number;
	vehicleId: id;
	vehicleInterventionResourceId: id;
	technicianInterventionResources: InterventionResource[];
	isRetour: boolean;
}

class InterventionPlanningEventModel extends Model implements IPlanningEventModel {
	constructor(attributes?: IInterventionPlanningEventAttributes) {
		super(attributes);
	}

	@computed
	public get anomalyCount(): number {
		return this.get('anomalyCount', 0);
	}

	@computed
	public get anomalyLevel(): number {
		return this.get('anomalyLevel', 0);
	}

	@computed
	public get startDate(): Date {
		return new Date(this.get('startDate'));
	}

	@computed
	public get endDate(): Date {
		return new Date(this.get('endDate'));
	}

	@computed
	public get vehicleId(): id {
		return this.get('vehicleId', 0);
	}

	@computed
	public get taskId(): id {
		return this.get('taskId', 0);
	}

	@computed
	public get vehicleInterventionResourceId(): id {
		return this.get('vehicleInterventionResourceId', 0);
	}

	@computed
	public get technicianInterventionResources(): InterventionResource[] {
		return this.get('technicianInterventionResources', []);
	}

	@computed
	public get operationLabels(): string[] {
		return this.get('operationLabels', []);
	}

	@computed
	public get technicianUrns(): string[] {
		return this.get('technicianUrns', []) || [];
	}

	@computed
	public get technicianIds(): id[] {
		return this.technicianUrns.map(urn => getIdFromUrn(urn));
	}

	@computed
	public get planningEventAnomalyCount(): number {
		return this.anomalyCount;
	}

	@computed
	public get planningEventAnomalyLevel(): number {
		return this.anomalyLevel;
	}

	@computed
	public get planningEventAllInterventionOperationsStatus(): 'all_done' | 'all_not_done' | 'all_other' | '' {
		return this.allInterventionOperationsStatus;
	}

	@computed
	public get planningEventStatusReference(): InterventionStatusReference {
		return this.statusReference;
	}

	@computed
	public get planningEventColor(): string {
		return this.get('color', '');
	}

	@computed
	public get allInterventionOperationsStatus(): 'all_done' | 'all_not_done' | 'all_other' | '' {
		return this.get('allInterventionOperationsStatus', '');
	}

	@computed
	public get statusReference(): InterventionStatusReference {
		return this.get('statusReference', '');
	}

	@computed
	public get planningEventSubTitle(): string | string [] {
		const arr: string[] = [];

		if (this.technicianUrns.length) {
			arr.push(
				collectionStaffMember
					.filter(m => this.technicianUrns.includes(m.urn))
					.map(m => m.staff.fullName)
					.join(', '),
			);
		}

		if (this.operationLabels.length) {
			arr.push(this.operationLabels.filter(label => label).join(', '));
		}

		return arr;
	}

	@computed
	public get planningEventDetails(): string {
		return new Intl.NumberFormat('fr-FR', { currency: 'EUR', style: 'currency' }).format(this.value / 100);
	}

	@computed
	public get planningEventRowId(): id {
		return this.get('vehicleId', 0);
	}

	@computed
	public get planningEventStartDate(): Date {
		return new Date(this.get('startDate'));
	}

	@computed
	public get planningEventEndDate(): Date {
		return new Date(this.get('endDate'));
	}

	@computed
	public get planningEventClassNames(): string[] {
		const arr: string[] = [];

		if (!this.technicianUrns.length) {
			arr.push('without-technicians');
		}

		if (this.get('animated')) {
			arr.push('animated');
		}

		return arr;
	}

	@computed
	public get value(): number {
		return this.get('value', 0);
	}

	public planningEventDetailsByDate(date: Date): string {
		const dateM = moment(date);
		const startDateM = moment(this.startDate);
		const value = (dateM.isSame(startDateM, 'day')) ? this.value / 100 : 0;
		return new Intl.NumberFormat('fr-FR', { currency: 'EUR', style: 'currency' }).format(value);
	}

	public planningEventTitleByDate(date: Date, withLocation = true, long = true): string {
		const startDateM = moment(this.startDate);
		const endDateM = moment(this.endDate);
		const dateM = moment(date);
		let title = '';

		if (dateM.isSame(startDateM, 'day')) {
			title += startDateM.format('LT');
		} else if (dateM.isSame(endDateM, 'day')) {
			title += `--:--`;
		}

		title += long ? ' ⮕ ' : ' ';

		if (dateM.isSame(endDateM, 'day')) {
			title += endDateM.format('LT');
		} else {
			title += `--:--`;
		}

		if (withLocation) {
			title += ' ' + this.activityLocationTitle;
		}

		return title;
	}

	public get activityLocationTitle() {
		return this.get('activityLocationTitle', '');
	}

	public set(attribute: IInterventionPlanningEventAttributes): this {
		return super.set(attribute);
	}

	public setAttributesFromInterventionCard(
		proto: Proto.Service.Intervention.PlanningInterventionCard,
		animated = false,
	): this {
		const { intervention, interventionResources, taskOperations } = proto;

		const interventionId = parseInt(getIdFromUrn(intervention?.urn || ''));

		const vehicleInterventionResource = interventionResources
			.find(ir => ir.resourceType?.reference === 'vehicle');

		const technicianInterventionResources = interventionResources
			.filter(ir => ir.resourceType?.reference === 'technician');

		const technicianUrns = technicianInterventionResources.map(ir => ir.resourceUrn?.toString() || '');

		const interventionStatus = intervention?.interventionStatus;

		return this.set({
			activityLocationTitle: taskOperations[0]?.taskZone?.name || '',
			allInterventionOperationsStatus: intervention?.allInterventionOperationsStatus || '',
			animated,
			anomalyCount: intervention?.anomalyCount,
			anomalyLevel: intervention?.anomalyLevel,
			card: proto,
			color: interventionStatus ? interventionStatusColors[interventionStatus.reference || ''] : '',
			endDate: moment(parseInt(intervention?.scheduleEndDate?.seconds?.toString() || '') * 1000).format(),
			id: interventionId,
			isRetour: proto.taskOperations.some(to => to.mainTaskOperationExtras?.length),
			operationLabels: taskOperations.map(to => (
				to.label || to.operation?.internalLabel || '')) || [],
			startDate: moment(parseInt(intervention?.scheduleStartDate?.seconds?.toString() || '') * 1000).format(),
			statusReference: interventionStatus?.reference || '',
			taskId: getIdFromUrn(taskOperations.find(to => to.task)?.task?.urn || ''),
			technicianInterventionResources: technicianInterventionResources
				.map(r => ({ resourceUrn: r.resourceUrn, urn: r.urn }) as InterventionResource),
			technicianUrns: technicianUrns,
			updatedAt: proto.intervention?.updatedAt?.seconds,
			value: intervention?.value || 0,
			vehicleId: parseInt(getIdFromUrn(vehicleInterventionResource?.resourceUrn || '')) || 0,
			vehicleInterventionResourceId: parseInt(getIdFromUrn(vehicleInterventionResource?.urn || '')),
		});
	}

	@computed
	public get isNightPlanned() {
		return !moment(this.planningEventStartDate).isSame(moment(this.planningEventEndDate), 'day');
	}

	public get urn() {
		return `$:intervention:intervention:${this.id}`;
	}

	public get updatedAt(): number | undefined {
		return this.get('updatedAt');
	}

	public get isRetour(): boolean {
		return this.get('isRetour', false);
	}
}

export const collectionStaffMember = new ApiCollection(StaffMemberModel);

export default InterventionPlanningEventModel;
